import { RouteRecordRaw } from 'vue-router';

import { Auth, HasAccountPermissions } from '$common/modules/auth/middelwares.ts';
import { HasFeature } from '$common/modules/feature-flags/middlewares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/welcome',
    meta: { middlewares: [Auth] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('./layouts/HelpWelcomeLayout.vue'),
        children: [
          {
            path: '',
            name: 'HelpWelcomeView',
            component: () => import('./views/HelpWelcomeView.vue'),
            meta: { title: 'Welcome' },
          },
          {
            path: 'you',
            name: 'HelpWelcomeYouView',
            component: () => import('./views/HelpWelcomeYouView.vue'),
            meta: { title: 'You' },
          },
          {
            path: 'core-data',
            name: 'HelpWelcomeCoreDataView',
            component: () => import('./views/HelpWelcomeCoreDataView.vue'),
            meta: { title: 'Core Data' },
          },
          {
            path: 'booking-data',
            name: 'HelpWelcomeBookingDataView',
            component: () => import('./views/HelpWelcomeBookingDataView.vue'),
            meta: {
              title: 'Booking Data',
              middlewares: [HasFeature('booking')],
            },
          },
          {
            path: 'administrative-data',
            name: 'HelpWelcomeAdministrativeDataView',
            component: () => import('./views/HelpWelcomeAdministrativeDataView.vue'),
            meta: {
              title: 'Administrative Data',
              middlewares: [HasFeature('business')],
            },
          },
          {
            path: 'promotion-data',
            name: 'HelpWelcomePromotionDataView',
            component: () => import('./views/HelpWelcomePromotionDataView.vue'),
            meta: {
              title: 'Promotion Data',
              middlewares: [HasFeature('artist_promotion')],
            },
          },
          {
            path: 'auditing',
            name: 'HelpWelcomeAuditingView',
            component: () => import('./views/HelpWelcomeAuditingView.vue'),
            meta: { title: 'Auditing' },
          },
          {
            path: 'search',
            name: 'HelpWelcomeSearchView',
            component: () => import('./views/HelpWelcomeSearchView.vue'),
            meta: { title: 'Search' },
          },
          {
            path: 'lists',
            name: 'HelpWelcomeListsView',
            component: () => import('./views/HelpWelcomeListsView.vue'),
            meta: { title: 'Lists' },
          },
          {
            path: 'tools',
            name: 'HelpWelcomeToolsView',
            component: () => import('./views/HelpWelcomeToolsView.vue'),
            meta: { title: 'Tools' },
          },
          {
            path: 'admin',
            name: 'HelpWelcomeAdminView',
            component: () => import('./views/HelpWelcomeAdminView.vue'),
            meta: {
              title: 'Admin',
              middlewares: [HasAccountPermissions],
            },
          },
        ],
      },
    ],
  },
];
